import $ from "jquery"

const closeMenu = () => {
    $('.jsMenuAnimate').removeClass('menu-animate');
    $('.jsNav').removeClass('open');
    $('body').removeAttr('style');
};

const openMenu = () => {
  $('.jsMenuAnimate').addClass('menu-animate');
  $('.jsNav').addClass('open');
  $('body').prop('style', 'overflow: hidden');
};

$('.jsMenuAnimate').on('click', function() {
  if($(this).hasClass('menu-animate')) {
    closeMenu();
  } else {
    openMenu();
  }
})

$(document).on('keydown', function (evt) {
  if (evt.keyCode === 27) {
    closeMenu();
  }
});

$('main').on('click', function () {
  closeMenu();
});

$(window).resize(function() {
  if($(window).width() > 1299) {
    closeMenu();
  }
});