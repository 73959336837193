import $ from "jquery"

let tileItem = $('.jsTileItem');
let $tileSlider = $('.jsTileSlider');



const tileSliderInit = (slider) => {
    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 300,
      fade: true,
      arrows: false,
      dots: false
    });
};

const tileSliderReinit = (slider) => {
  slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: true,
    arrows: false,
    dots: false
  });
}

if ($tileSlider) {

  tileItem.mouseover(function() {
    let $tileSlider = $(this).find('.jsTileSlider');
    if ($tileSlider.hasClass('slick-slider')) {
      $tileSlider.slick('unslick');
    }
    tileSliderInit($tileSlider);
  });

  tileItem.mouseout(function(e) {
    let currentSlider = $(this).find('.jsTileSlider');
    currentSlider.slick('unslick');
    tileSliderReinit(currentSlider);
  });
}

//

const tilePreviewsBlock = $('.jsTilePreviews');

if (tilePreviewsBlock.length > 0) {

  const setTilesCount = (parent) => {
    let tiles = parent.find('.tile__item');
    let maxCount = 9;
    let difference = tiles.length - maxCount;

    if (tiles.length > maxCount) {
      tiles.each(function(elem, value) {
        if (elem < difference) {
          $(value).addClass('hide-lg');
        }
      });
    }
  }
  
  tilePreviewsBlock.each(function(elem, value) {
    let currentTileBlock = $(value);
    setTilesCount(currentTileBlock);
  });
}

//

let tilePart = $('.jsTileTitle');

if (tilePart.length !== 0) {

  tilePart.each(function(elem, value) {
    let tileTitle = $(value).data('title');
    
    let currentClass = $(`.tile-title--${tileTitle}.first`);
    if (currentClass.length === 0) {
      $(`.tile-title--${tileTitle}`).first().addClass('first');
    }
  });
}

