import $ from "jquery"

const $agreed = $('#agreed');
const $feedbackBtn = $('.jsFbSubmit');
let $select = $('.jsSelect');
let $selectPlaceholder = $('.jsSelectPlaceholder');

$agreed.on('change', function() {
  if ($(this).prop('checked')) {
    $feedbackBtn.removeAttr('disabled');
  } else {
    $feedbackBtn.prop('disabled', 'disabled')
  }
});

let placeholder = $selectPlaceholder.text();

$select.select2({
  placeholder: `${placeholder}`,
  minimumResultsForSearch: -1
});


$(window).resize(function() {
  $select.select2({
    placeholder: `${placeholder}`,
    minimumResultsForSearch: -1
  });
});

let form = $('.jsFeedbackForm');

let overlay = $('.jsOverlay');
let modal = $('.jsModal');
let successModal = $('#feedback-success');
let errorModal = $('#feedback-error');

const formReset = () => {
  form[0].reset();
  $select.val('').trigger('change');
};

const sendFeedback = (url, data) => {
  $.ajax({
    method: 'POST',
    url: url,
    data: data,
    success: function () {
      overlay.show();
      successModal.show();
      formReset();
    },
    failure: function () {
      overlay.show();
      errorModal.show();
    }
  });
};

$feedbackBtn.on('click', function () {
  form.addClass('send');
})

form.on('submit', function(e) {
  e.preventDefault();
  let result = form.serializeArray();
  let action = form.attr('action');
  sendFeedback(action, result);
});

let closeModalBtn = $('.jsCloseModal');

let closeModal = () => {
  modal.hide();
  overlay.hide();
};

overlay.on('click', function() {
  closeModal();
});

closeModalBtn.on('click', function() {
  closeModal();
});

$(document).on('keydown', function (evt) {
  if (evt.keyCode === 27) {
    closeModal();
  }
});