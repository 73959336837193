import $ from "jquery";
window.jQuery = $;

let animateBlock = $('.jsAnimateContainer');

//Однократная анимация
if (sessionStorage.getItem('animated') !== null) {
  animateBlock.hide();
} else {
  window.onload = function() {
    let animateMask = $('.animate-mask');

    animateMask.addClass('active');

  setTimeout(function() {
    animateBlock.prop('style', 'opacity: 0;');
  }, 3500);

  setTimeout(function() {
    animateBlock.hide();
  }, 4000);
  };
  sessionStorage.setItem('animated', 'true');
}
