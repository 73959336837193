import './css/select2.min.css';
import './css/jquery.background-video.css';
import './css/slick.css';
import './sass/index.scss';

import './images/favicon.png';
import './images/logo.svg';
import './images/type.svg';
import './images/arrow-white.svg';
import './images/check-mark.svg';
import './images/pin.svg';
import './images/home.svg';
import './images/mail.svg';
import './images/phone.svg';
import './images/globe.svg';
import './images/pattern.svg';
import './images/pin-gold.svg';
import './images/logo-mask.svg';
import './images/play.svg';
import './images/garpix-logo.svg';

//
import './images/img/1.png';
import './images/img/2.png';
import './images/img/3.png';
import './images/img/4.png';
import './images/img/5.png';
import './images/img/6.png';
import './images/img/7.png';
import './images/img/8.png';
import './images/img/9.png';
import './images/img/10.png';

import './images/img/jet.png';
import './images/img/logo.png';
//
import './images/project-animate.png';


import $ from "jquery";
window.jQuery = $;
require("@fancyapps/fancybox");

import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";
import './components/select2.min.js';

import './components/pagetransitions.js';
import './components/jquery.background-video.js';
import './components/slick.js';
import './components/search.js';
import './components/menu.js';
import './components/form.js';
import './components/slider.js';
import './components/map.js';
import './components/collection.js';
import './components/tile.js';
import './components/animation.js';


$(document).ready(() => {

  let $tabControls = $('.jsControl');

  $tabControls.on('click', function() {
    let currentId = $(this).prop('id');
    let container = $(this).closest('.jsTabs');
    let tabs = container.find('.jsTargetTab');

    tabs.removeClass('active');
    $(`[data-id="${currentId}"]`).addClass('active');
  });

  let searchBtn = $('.jsSearchOpen');
  let searchPage = $('.jsSearch');

  searchBtn.on('click', function () {
    searchPage.removeClass('display-none');
  });

  let closeSearchBtn = $('.jsSearchClose');

  let closeSearch = () => {
    searchPage.addClass('display-none');
  };

  closeSearchBtn.on('click', function () {
    closeSearch();
  });

  $(document).on('keydown', function (evt) {
    if (evt.keyCode === 27) {
      closeSearch();
    }
  });


  $('.jsFadeSlider').slick({
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    arrows: false, 
    dots: false,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: 'linear'
  });

  let bgBlock = $('.bgv');

  if (bgBlock.length !== 0) {

  }
});
