import $ from "jquery"

let showArrows = (arrows, slides, allSlides) => {
  if (allSlides <= slides ) {
    arrows.addClass('display-none');
  } else {
    arrows.removeClass('display-none');
  }
}

let showSliderArrows = (slider, allSlides) => {
  let currentBlock = slider.closest('.jsSliderBlock');
  let arrowsBlock = currentBlock.find('.jsArrowsBlock');
  let slides = slider.slick('slickGetOption','slidesToShow');

  showArrows(arrowsBlock, slides, allSlides);
}

const responsiveDefault = [{
  breakpoint: 1200,
  settings: {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: false
  }
}];

const sliderInit = (slider, slideCount, autoplay, responsive = responsiveDefault) => {
  let currentBlock = slider.closest('.jsSliderBlock');
  let prev = currentBlock.find('.jsPrev');
  let next = currentBlock.find('.jsNext');
  
  slider.slick({
    slidesToShow: slideCount,
    slidesToScroll: 1,
    autoplay: autoplay,
    arrows: false,
    dots: false,
    responsive: responsive
  });

  next.on ("click", function (e) {
    slider.slick("slickNext");
  });

  prev.on("click", function (e) {
    slider.slick("slickPrev");
  });


  let allSlides = slider.slick("getSlick").slideCount;
  showSliderArrows(slider, allSlides);

  $(window).resize(function() {
    showSliderArrows(slider, allSlides);
  })
};

let $sertSlider = $('.jsSertSlider');

if ($sertSlider.length !== 0) {

  let sertSliderCount = 3;
  let sertAutoplay = false;
  let sertResponsive = [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ];

  sliderInit($sertSlider, sertSliderCount, sertAutoplay, sertResponsive);

  let allSlides = $sertSlider.slick("getSlick").slideCount;
  $('.jsAllSlides').text(allSlides);

  $sertSlider.on("afterChange", function (event, slick, currentSlide, nextSlide) {
    $('.jsCurrentSlide').text(currentSlide+1);
    $('.jsAllSlides').text(slick.slideCount);
  });
}

let $newsSlider = $('.jsNewsSlider');

if ($newsSlider.length !== 0) {
  let newsSliderCount = 1;
  let newsAutoplay = true;
  sliderInit($newsSlider, newsSliderCount, newsAutoplay);
}

const $collectSlider = $('.jsCollectSlider');

if ($collectSlider.length !== 0) {
  let collectSliderCount = 3;
  let collectAutoplay = false;
  sliderInit($collectSlider, collectSliderCount, collectAutoplay);
}