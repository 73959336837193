import $ from "jquery";
window.jQuery = $;

const lang = $('html').attr('lang');
let searchForm = $('.jsSearch');
let resultMessage = $('.jsResultMessage').clone();
let failMessage = $('.jsFailResult').clone();

// $.holdReady
// 'Accept-Language'


let loadResults = function (url, qrry) {
  let real_url = `${url}`;
  if(lang !== '') {
    real_url = `/${lang}${real_url}`;
  }
  console.log(real_url);
  $.ajax({
    // headers: {
    //   'Accept-Language': lang,
    // },
    url: real_url,
    data: {
      'q': qrry,
    },

    success: function (data) {
      if (data.length !== 0) {
        $('#search_results').empty();

        let getFullResult = (data) => {
          return Array.from(data).map((it) => (`<a href="${it.url}" class="search__list-item">
            <p class="search__category">${it.type}</p>
            <h2 class="search__item-title">${it.title}</h2>
          </a>`.trim()));
        };

        Array.from(data).map((it) => (console.log(it)));

        let fullResult = getFullResult(data);

        $('#search_results').append(fullResult);
      } else {
        $('#search_results').empty();

        let mssgStatus = resultMessage.find('.jsSearchRequest');
        mssgStatus.text(qrry);

        $('#search_results').append(resultMessage);
      }
      
    },
    failure: function () {
      $('#search_results').append(failMessage);
    }
  });
};


$("#search_form_ajx").on('submit', function (e) {
  e.preventDefault();
  
  let action = searchForm.attr('action');
  var qrry = $("input[name='qrry']").val();
  loadResults(action, qrry);
});