import $ from "jquery";
window.jQuery = $;

let productSlider = $('.jsProductSlider');
let productInput = productSlider.find('.jsControl');

productInput.on('click', function() {
  let currentId = $(this).prop('id');

  let currentContainer = $(`.jsElementInfo[data-elem="${currentId}"]`);

  let getInfoField = currentContainer.find('.jsGetInfo');

  getInfoField.each(function(elem, value) {
    let currentContent = $(value).text();
    let currentType =  $(value).data('id').split('-')[1];
    let targetField = $(`#${currentType}`);
    let targetTableData = $(`#table-${currentType}`);
    
    targetField.text(currentContent);
    targetTableData.text(currentContent);
  });


});