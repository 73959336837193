import $ from "jquery"

if ($('#map').length !== 0) {
  let pinSrc = $('.jsPin').prop('src');
  let address = [];

  let partner = $('.jsPartner');

  partner.each(function(elem, value) {
    let element = $(value);
    let currentX = element.find('.jsX').text();
    let currentY = element.find('.jsY').text();
    let currentAddress = element.find('.jsAddress').text();

    let newPoint = {};

    newPoint.x = currentX;
    newPoint.y = currentY;
    newPoint.content = currentAddress;

    address.push(newPoint);
  });


  setTimeout(() => {
    ymaps.ready(function () {
      var myMap = new ymaps.Map('map', {
        center: [55.884721, 37.603720],
        zoom: 12
      }, {
          searchControlProvider: 'yandex#search'
        });
  
        address.map((item) => {
          var myPlacemark = new ymaps.Placemark([item.x, item.y], {
            hintContent: item.content
          },
          {
            iconLayout: 'default#image',
            iconImageHref: pinSrc,
            iconImageSize: [64, 64],
            iconImageOffset: [-32, -64]
          })
  
        myMap.geoObjects
          .add(myPlacemark);
        })

       let partner = $('.jsPartner');

      partner.on('click', function() {
        let currentX = $(this).find('.jsX').text();
        let currentY = $(this).find('.jsY').text();

        myMap.setCenter([currentX, currentY], myMap.getZoom(), {duration: 1000});
      }); 
    });
  });


}
